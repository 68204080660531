import Paths from "router/Paths"
import { PlusSvg, EnvelopSvg, StatisticsSvg, UsersSvg, BuildingSvg } from "svg"

export default [
	{
		title: "Nowe zgłoszenie",
		svg: <PlusSvg/>,
		path: Paths.Issue.New,
		extra_classes: "add-issue",
		available_for: [ "manage_issues", "manage_all_issues", "manage_own_issues", "manage_all_company_issues", "manage_all" ]
	},
	{
		title: "Zgłoszenia",
		svg: <EnvelopSvg/>,
		path: Paths.Issue.List,
		available_for: [ "manage_issues", "manage_all_issues", "manage_own_issues", "manage_all_company_issues", "manage_all" ]
	},
	// {
	// 	title: "Statystyki",
	// 	svg: <StatisticsSvg/>,
	// 	path: Paths.Statistics
	// },
	{
		title: "Użytkownicy",
		svg: <UsersSvg/>,
		path: Paths.User.List,
		available_for: [ "manage_users", "manage_company_users", "manage_all" ]
	},
	{
		title: "Podwykonawcy",
		svg: <BuildingSvg/>,
		path: Paths.Company.List,
		available_for: [ "manage_all", "manage_companies" ]
	}
]