import axios from 'axios';
import Storage from 'utils/Storage';

const API = axios.create({
	baseURL: window.config.API_URL,
	withCredentials: true
});


API.interceptors.request.use( config => {
	const token = Storage.get( "access_token" );
	if ( token && config.headers ) config.headers.Authorization = `Bearer ${ token }`;
	return config;
}, error => Promise.reject( error ));


export default API;