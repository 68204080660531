import API from "./API";

const FileAPI = {

	upload: async( file ) => {

		const { data } = await API.post( "/files", { file }, {
			headers: { "Content-Type": "multipart/form-data" }
		});

		return data.id;
	},

	uploadFew: async( files ) => {
		
		if ( !files || !files.length ) return [];
		
		return await Promise.all( files.map( async( f ) => {
			return FileAPI.upload( f );
		}));
	},

	delete: ( guid ) => API.delete( `/files/${ guid }`)
}

export default FileAPI;