import { useLogOut } from "context/AuthContext";
import { LogOutSvg } from "svg";

export default function HeaderLogOut() {
	
	const logOut = useLogOut();

	return (
		<button 
			type="button"
			className="header-logout"
			onClick={ logOut }	
		>
			Wyloguj
			<LogOutSvg/>
		</button>
	)
}