import Header from "components/main/Header";
import Footer from "components/main/Footer";
import Sidebar from "./components/Sidebar";

import "./main-layout.scss";

export default function MainLayout({ children }) {
		
	return (
		<div className="main-layout">

			<Header/>

			<div>

				<Sidebar/>

				<main>
					<div> 
						{ children }
					</div>

					<Footer/>
				</main>
			</div>
		</div>
	)
}