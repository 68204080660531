import { Link } from "react-router-dom";
import Paths from "router/Paths";

import { useUser } from "context/AuthContext";
import UserPhotoName from "components/general/UserPhotoName";

export default function HeaderUser() {
	
	const user = useUser();

	return (
		<Link to={ Paths.Profile }>
			<UserPhotoName user={ user }/>
		</Link>
	)
}