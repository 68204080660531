import { lazy, Suspense } from "react";
import { Route, Routes as Switch, Navigate } from "react-router-dom";

import Paths from "./Paths";
import Loader from "components/general/Loader";

const AshWaste = lazy(() => import( "pages/Public/AshWaste" ));
const BulkyWaste = lazy(() => import( "pages/Public/BulkyWaste" ));
const CheckIssueStatus = lazy(() => import( "pages/Public/CheckIssueStatus" ));

const LogIn = lazy(() => import( "pages/Auth/LogIn" ));
const ForgotPassword = lazy(() => import( "pages/Auth/ForgotPassword" ));
const PasswordReset = lazy(() => import( "pages/Auth/PasswordReset" ))

const NewIssue = lazy(() => import( "pages/Issue/NewIssue" ));
const IssueList = lazy(() => import( "pages/Issue/IssueList" ));
const EditIssue = lazy(() => import( "pages/Issue/EditIssue" ));
const UserList = lazy(() => import( "pages/User/UserList" ));
const AddEditUser = lazy(() => import( "pages/User/AddEditUser" ));
const Profile = lazy(() => import( "pages/User/Profile" ));
const CompanyList = lazy(() => import( "pages/Company/CompanyList" ));
const AddEditCompany = lazy(() => import( "pages/Company/AddEditCompany" ));

const PublicPages = [
	{
		path: Paths.Public.AshWaste,
		Component: AshWaste
	},
	{
		path: Paths.Public.BulkyWaste,
		Component: BulkyWaste
	},
	{
		path: Paths.Public.CheckIssueStatus,
		Component: CheckIssueStatus
	}
]

const NotAuthPages = [
	{
		path: Paths.Auth.Login,
		Component: LogIn
	},
	{
		path: Paths.Auth.ForgotPassword,
		Component: ForgotPassword
	},
	{
		path: Paths.Auth.PasswordReset,
		Component: PasswordReset
	}
]

const NeedAuthPages = [
	{
		path: Paths.Issue.New,
		Component: NewIssue
	},
	{
		path: Paths.Issue.List,
		Component: IssueList
	},
	{
		path: Paths.Issue.Edit(),
		Component: EditIssue
	},
	{
		path: Paths.User.List,
		Component: UserList
	},
	{
		path: [ Paths.User.Add, Paths.User.Edit()],
		Component: AddEditUser
	},
	{
		path: Paths.Profile,
		Component: Profile
	},
	{
		path: Paths.Company.List,
		Component: CompanyList
	},
	{
		path: [ Paths.Company.Add, Paths.Company.Edit()],
		Component: AddEditCompany
	}
]


function createRouter( pages ) {
	return (
		<Suspense fallback={ <Loader style={{ margin: "auto" }}/>}>
			<Switch>
				{ pages.map(({ path, Component }) => {

					return Array.isArray( path ) 
						? path.map( p => (
							<Route
								exact
								key={ p }
								path={ p } 
								element={ <Component/> }
							/>
						))
						: <Route
							exact
							key={ path }
							path={ path } 
							element={ <Component/> }
						/>
				})}

			</Switch>
		</Suspense>
	)
}

export const PublicRoutes = () => createRouter( PublicPages );

export const NeedAuthRoutes = () => createRouter( NeedAuthPages );

export const NotAuthRoutes = () => createRouter( NotAuthPages );