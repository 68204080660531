import API from "./API";
import { prepareUserData } from "utils/userDataHelpers";

export default {

	all: ( params = {}, cancelToken ) => API.get( "/manage/users", {
		params: {
			...params,
			limit: params.per_page || params.limit || 10,
		},
		cancelToken
	}),

	byId: ( id, cancelToken ) => API.get( `/manage/users/${ id }`, { cancelToken }),

	add: async( data ) => {

		const password = data.password;
		data = await prepareUserData( data );	
		
		return API.post( `/manage/users`, {
			...data,
			password
		})
	},

	update: async( id, data ) => {
		data = await prepareUserData( data );
		return API.post( `/manage/users/${ id }`, data );
	},

	delete: id => API.delete( `/manage/users/${ id }` ),

	getAvatarURl: ( id, avatar_id ) => id && avatar_id 
		? `${ window.config.API_URL }/manage/users/${ id }/avatar/${ avatar_id }`
		: ""
}