import { Link } from "react-router-dom";
import Paths from "router/Paths";
import { ReactComponent as Logo } from "svg/logo.svg";
import HeaderUser from "./components/HeaderUser";
import HeaderLogOut from "./components/HeaderLogOut";

import "./header.scss";

export default function Header() {
		
	return (
		<header className="header">

			<div className="header-container"> 

				<Link 
					to={ Paths.Issue.List }
					aria-label="Powrót do strony głównej" 
					className="header-logo">
					<Logo />
				</Link>

				<h1> Referat Gospodarki Odpadami Wydział Środowiska </h1>

				<div className="header-right"> 
					<HeaderUser/>
					<HeaderLogOut/>
				</div>
			</div>
		</header>
	)
}