import API from "./API";
import { prepareUserData } from "utils/userDataHelpers";

export default {

	logIn: data => API.post( "/auth/login", {
		email: data.email,
		password: data.password
	}),

	refreshToken: () => API.get( "/auth/refresh-token" ),

	me: () => API.get( "/auth/me" ),

	updateMe: async( data ) => {

		const current_password = data.current_password;
		const password = data.password;
		const password_confirmation = data.password_confirmation;

		data = await prepareUserData( data );

		return API.post( "/auth/me", {
			...data,
			current_password: current_password,
			password: password,
			password_confirmation: password_confirmation,
		})
	},

	forgotPassword: email => API.post( "/auth/forgot-password", { email }),

	resetPassword: data => API.post( "/auth/reset-password", {
		token: data.token,
		email: data.email,
		password: data.password,
		password_confirmation: data.password_confirmation
	})
}