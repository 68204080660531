import Footer from "components/main/Footer";
import "./not-logged-in-layout.scss";

export default function NotLoggedInLayout({ children }) {
		
	return (
		<div className="not-logged-in-layout">
		 
		 	<main> 
				{ children }
			</main>
			
			<Footer/>
		</div>
	)
}