import React, { useEffect, useMemo, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import API from "api/API";
import AuthAPI from "api/AuthAPI";
import Paths from "router/Paths";
import Storage from "utils/Storage";
import { PublicRoutes, NeedAuthRoutes, NotAuthRoutes } from 'router/Routes';

import NotLoggedInLayout from "layouts/NotLoggedInLayout";
import MainLayout from "layouts/MainLayout";
import Loader from "components/general/Loader";

const AuthContext = React.createContext();

const PublicPaths = [
	Paths.Public.AshWaste,
	Paths.Public.BulkyWaste,
	Paths.Public.CheckIssueStatus
]

const NoAuthPaths = [
	Paths.Auth.Login,
	Paths.Auth.ForgotPassword,
	Paths.Auth.PasswordReset
]

let refresh_timer;

export default function AuthContextProvider() {
	
	const navigate = useNavigate();
	const location = useLocation();

	const [ loading, setLoading ] = useState( true );
	const [ user, setUser ] = useState( null );

	const checkPermission = ( permission ) => {
		
		if ( Array.isArray( permission )) {

			for ( let i = 0; i < permission.length; i++ ) {
				if ( user.abilities.includes( permission[ i ])) return true;
			}

			return false;

		} else {
			return user.abilities.includes( permission ); 
		}
	}


	const refreshUser = async() => {
		const { data } = await AuthAPI.me();
		setUser( data );
	}


	const getUser = async() => {

		try {
			const { data } = await AuthAPI.me();
			setUser( data );

			( NoAuthPaths.includes( location.pathname ) || location.pathname === "/" ) && navigate( Paths.Issue.List )
			setLoading( false );

		} catch ( e ) {
			logOut()
			return Promise.reject( e );
		}
	}


	const updateMe = async( _data_ ) => {
		const { data } = await AuthAPI.updateMe( _data_ );
		setUser({...user, ...data })
	}


	const runRefreshTimer = () => {
		const expires_at = +( Storage.get( "token_expires_at" ));
		if ( !expires_at || isNaN( expires_at )) return;

		const timeout = new Date( expires_at ) - new Date() - 10000;
		refresh_timer = setTimeout(() => refreshToken(), timeout );
	}


	const updateTokens = ({ access_token, refresh_token }) => {

		Storage.set( "access_token", access_token.token );
		Storage.set( "refresh_token", refresh_token.token );

		document.cookie = "token=" + access_token.token + "; path=/";

		clearTimeout( refresh_timer );

		const expires_at = +new Date( access_token.expires_at );
		if ( expires_at ) {
			Storage.set( "token_expires_at", expires_at );
			runRefreshTimer();	
		}
	}


	const logIn = async({ email, password }) => {

		const { data } = await AuthAPI.logIn({ email, password });

		updateTokens( data );
		getUser();
	}


	const logOut = () => {

		document.cookie = "token=; path=/; Max-Age=0;";

		Storage.remove( "access_token" );
		Storage.remove( "refresh_token" );
		Storage.remove( "token_expires_at" );

		setUser( null );
		setLoading( false );

		navigate( Paths.Auth.Login )
	}


	const refreshToken = async() => {

		const refresh_token = Storage.get( "refresh_token" );
		if ( !refresh_token ) return;

		const { data } = await AuthAPI.refresh( refresh_token );
		updateTokens( data );
	}


	const Content = useMemo(() => {

		if ( PublicPaths.includes( location.pathname )) return <PublicRoutes/>

		if ( loading ) return <Loader style={{ margin: "auto" }}/>

		return user
			? <MainLayout> <NeedAuthRoutes/> </MainLayout>
			: <NotLoggedInLayout> <NotAuthRoutes/> </NotLoggedInLayout>
	}, [ user, loading ])


	useEffect(() => {
		
		API.interceptors.response.use( res => {
			if ( res?.data?.code === 401 ) logOut();
			return res;
		}, err => {
			
			const resp = err?.response;
			
			if ( resp?.status === 401 && !/\/auth/.test( resp?.config?.url )) logOut();
			return Promise.reject( err );
		});
	}, [])


	useEffect(() => {

		const p = location.pathname;
		if ( PublicPaths.includes( p )) return;

		document.cookie = "token=; path=/; Max-Age=0;";
		const access_token = Storage.get( "access_token" );
		
		if ( access_token ) {
			getUser()
			.then(() => {
				document.cookie = "token=" + access_token + "; path=/";
				runRefreshTimer();
			})
		} else {
			!NoAuthPaths.includes( p ) && navigate( Paths.Auth.Login );
			setLoading( false );
		}
	}, [])


	return (
		<AuthContext.Provider value={{
			user,
			refreshUser,
			checkPermission,
			updateMe,
			logIn,
			logOut
		}}>
			{ Content }
		</AuthContext.Provider>
	)
}


export const useAuthContext = () => useContext( AuthContext );

export const useUser = () => useAuthContext().user;

export const useCheckPermission = () => useAuthContext().checkPermission;

export const useLogOut = () => useAuthContext().logOut;

export const useLogIn = () => useAuthContext().logIn;