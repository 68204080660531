import FileAPI from "api/FileAPI";

export const prepareUserData = async( data ) => {

	let avatar_file_id = data.avatar_file_id;
	if ( data.new_avatar ) {
		avatar_file_id = await FileAPI.upload( data.new_avatar );
	}

	return {
		firstname: data.firstname,
		lastname: data.lastname,
		phone: data.phone,
		email: data.email,
		role_id: data.role_id,
		company_id: data.company_id,
		avatar_file_id
	}
}